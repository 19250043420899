<template lang="html">
  <div id="contact-page">

    <section class="main-section">
      <div class="container oversized-container">
        <div class="row justify-content-center">
          <div class="col-lg-6 col-form">
            <div class="box-form left">
              <h1 class="mb-sm-2 title">Contact us</h1>
              <p>Email us at <a class="txt-red" href="mailto:info@beaprospect.com">info@beaprospect.com</a> or send us a message.</p>

              <b-form class="row" @submit="onSubmit">
                <b-form-group class="col-12 cus-f-group-1" label="Full Name">
                  <b-form-input type="text" v-model="form.name" required placeholder=""></b-form-input>
                </b-form-group>

                <b-form-group class="col-12 cus-f-group-1" label="Email">
                  <b-form-input type="email" v-model="form.email" required placeholder=""></b-form-input>
                </b-form-group>

                <b-form-group class="col-12 cus-f-group-1" label="Subject">
                  <b-form-input type="text" v-model="form.reason" required placeholder=""></b-form-input>
                </b-form-group>

                <b-form-group class="col-12 cus-f-group-1 mb-3" label="Message">
                  <b-form-textarea
                    required
                    v-model="form.msg"
                    placeholder=""
                    rows="4"
                    max-rows="4"
                  ></b-form-textarea>
                </b-form-group>

                <div class="col-12 pt-1">
                  <p class="text-center">
                    <b-button type="submit" class="btn-send">Send</b-button>
                  </p>
                </div>
              </b-form>
            </div>
          </div>
        </div>
      </div>
    </section>

  </div>
</template>

<script>
export default {
  data(){
    return{
      form: {
        name: null,
        lastName: null,
        email: null,
        reason: null,
        msg: null,
      }
    }
  },

  methods: {
    makeToast(variant = null, msg, title) {
      this.$bvToast.toast(msg, {
        title: title,
        variant: variant,
        solid: true,
        toaster: 'b-toaster-bottom-center',
        appendToast: true
      })
    },

    onSubmit(evt) {
      evt.preventDefault();

      var params = this.form;
      var apiURL = "https://beaprospect.com/back-temp/api/contact";

      axios.post( apiURL,params )
      .then( (response) => {
        // alert(response.data.response);
        this.makeToast('success', response.data.response, 'Message sent');
        // alert(response.data.response);

        for (let prop in this.form) {
          this.form[prop] = '';
        }
      })
      .catch( (error) => {
        // console.log(error.response.data);
        let errors = error.response.data;

        for (let prop in errors) {
          // console.log(errors[prop]);
          this.makeToast('danger', errors[prop], '¡Error!');
        }
      });
    }
  },
}
</script>
