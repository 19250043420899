<template lang="html">
  <div id="home-page">

    <section class="intro-section">
      <div class="placed-backg bg"></div>

      <div class="box">
        <p>
          <img class="logo-2be" src="public/images/2be.svg" alt="2BE">
        </p>
        <p class="mt-4">
          <img class="logo-b" src="public/images/logo-b.png" alt="BEAPROSPECT">
        </p>

        <h6 class="mt-4 presented">PRESENTED BY BEAPROSPECT INC <span class="d-block d-sm-inline-block f-w-500">(Soyez un Prospect Inc)</span></h6>

        <h5 class="mt-2"><a class="f-w-700 link-info" href="mailto:info@beaprospect.com">INFO@BEAPROSPECT.COM</a></h5>

        <p class="p-extra-links">
          <router-link class="f-w-700 link-info mx-3" to="/contact">Contact</router-link>
          <span>|</span>
          <!-- <router-link class="f-w-700 link-info mx-3" to="/privacy-policy">Privacy Policy</router-link> -->
          <a class="f-w-700 link-info mx-3" target="_blank" download href="public/docs/2be_Privacy_Policy.pdf">Privacy Policy</a>
          <span>|</span>
          <!-- <router-link class="f-w-700 link-info mx-3" to="/terms-conditions">Terms & Conditions</router-link> -->
          <a class="f-w-700 link-info mx-3" target="_blank" download href="public/docs/Terms_and_conditions.pdf">Terms & Conditions</a>
        </p>

        <p class="mt-4">
          <a class="mx-2 btn-app" target="_blank" href="https://play.google.com/store/apps/details?id=com.beaprospect.reboot">
            <img src="public/images/google-play.svg" alt="Google Play">
          </a>

          <a class="mx-2 btn-app" target="_blank" href="https://apps.apple.com/mx/app/2be/id1107684129">
            <img src="public/images/app-store.svg" alt="App Store">
          </a>
        </p>
      </div>
    </section>

  </div>
</template>

<script>
export default {
}
</script>
